import { makeAutoObservable } from "mobx";
import BarpLookupStore from "./barp-lookup-store";
import appConstants from "./constants";
import BarpQuoteStore from "./barp-quote-calc-store";

const defaultFullQuoteOptions = {
  engineSizes: [],
  vehicleTypes: [],
  drivingExperience: [],
  accidents: [],
  yearOfManufacture: [],
};
class BarpMotorStore {
  constructor() {
    makeAutoObservable(this);
    const { ENUMS, LEAD } = appConstants.BARP_MOTOR;

    this.lookups = {
      fullQuote: new BarpLookupStore(this, '/api' + ENUMS, defaultFullQuoteOptions),
    };
    this.quote = new BarpQuoteStore(
      this,
      {
        fullQuote: '/api' + LEAD.CREATE,
      },
      "reference"
    );
  }

  getFullQuote(formValues) {
    const request = {
      ...formValues,
      phoneNumber:
        String(1246) + String(formValues.phoneNumber).substring(0, 7), //Adding prefix to phone number,
      dateOfBirth: formValues.drivers[0].dateOfBirth,
    };

    request.drivers[0].retired = request.drivers[0].retired === "true";

    return this.quote.getFullQuote(request, "post");
  }
}

export default BarpMotorStore;
