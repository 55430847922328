import { makeAutoObservable } from "mobx";
import appConstants from "./constants";
import BarpLookupStore from "./barp-lookup-store";
import BarpQuoteStore from "./barp-quote-calc-store";

const defaultENUMS = {
  coverTypes: [],
  foundationTypes: [],
  numberOfClaims: [],
  parishes: [],
  occupancyTypes: [],
  propertyLocations: [],
  roofTypes: [],
  wallTypes: [],
};

class BarpHomeStore {
  constructor() {
    makeAutoObservable(this);
    const { ENUMS, LEAD } = appConstants.BARP_HOME;

    this.lookups = {
      fullQuote: new BarpLookupStore(this, '/api' + ENUMS, defaultENUMS),
    };

    this.quote = new BarpQuoteStore(
      this,
      {
        fullQuote: '/api' + LEAD.CREATE,
      },
      "reference"
    );
  }

  getFullQuote(formValues) {
    const isBuildingEnabled =
      formValues.coverDetails.coverType === "home" ||
      formValues.coverDetails.coverType === "home-contents";
    const isContentsEnabled =
      formValues.coverDetails.coverType === "contents" ||
      formValues.coverDetails.coverType === "home-contents";

    const request = {
      ...formValues,
      phoneNumber:
        String(1246) + String(formValues.phoneNumber).substring(0, 7),
      coverDetails: {
        ...formValues.coverDetails,
        buildingValue: isBuildingEnabled
          ? +formValues.coverDetails.buildingValue
          : 0,
        contentsValue: isContentsEnabled
          ? +formValues.coverDetails.contentsValue
          : 0,
        jewelleryValue: isContentsEnabled
          ? +formValues.coverDetails.jewelleryValue
          : 0,
        jewelleryRisksCoverage: isContentsEnabled
          ? formValues.coverDetails.jewelleryRisksCoverage
          : "",
        electronicsValue: isContentsEnabled
          ? +formValues.coverDetails.electronicsValue
          : 0,
        electronicsRisksCoverage: isContentsEnabled
          ? formValues.coverDetails.electronicsRisksCoverage
          : "",
      },
      propertyDetails: {
        ...formValues.propertyDetails,
        parish: +formValues.parish,
        parishArea: +formValues.parishArea,
        leftUnoccupied: formValues.propertyDetails.leftUnoccupied === "true",
        separatedFromNeighbour:
          formValues.propertyDetails.separatedFromNeighbour === "true",
        alarmSystem: formValues.propertyDetails.alarmSystem === "true",
      },
      personalDetails: {
        numberOfClaims: "0",
      },
    };

    return this.quote.getFullQuote(request, "post");
  }
}

export default BarpHomeStore;
